import AutoRenew from '@mui/icons-material/Autorenew';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import {
    Button,
    IconButton,
    styled,
    Tab,
    Tabs,
} from '@mui/material';
import type {
    MLSkillDto,
    ProjectDto,
    TierDto,
} from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom';

import {
    getIsAIUnitEnabled,
    getSkillReplicas,
    rollbackMLSkill,
    stopMLSkill,
    updateMLSkill,
} from '../../../api/client/deployerManagerClient';
import { getMlVersionsByPackageId } from '../../../api/client/pkgManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import type { BreadcrumbProps } from '../../../components/Breadcrumbs';
import {
    DialogPopup,
    DialogType,
    MlSkillUpdateDialog,
} from '../../../components/Dialog';
import HeroInfoIcon from '../../../components/HeroInfoIcon';
import type { ButtonItem } from '../../../components/Section';
import Section from '../../../components/Section';
import { WithVisibility } from '../../../components/WithVisibility';
import URLManager from '../../../config/URLManager';
import type {
    MlPackageTier,
    RequestedMemory,
} from '../../../constants/AiappConstants';
import {
    GPU_AIUNITS,
    RESOURCE_PER_REPLICA_CONFIG,
} from '../../../constants/AiappConstants';
import { AppPermissions } from '../../../enums/Authorization';
import { SkillUpdateType } from '../../../enums/SkillUpdate';
import type FeatureFlagManager from '../../../feature-flag/FeatureFlagManager';
import { PermissionsContext } from '../../../providers/PermissionsProvider';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import {
    computeMlSkillResourcePerReplica,
    copyToClipBoard,
    dataFormatter,
    downloadReport,
    extractErrorMessage,
    isMlSkillReportDownloadEnabled,
    isPublicCapable,
} from '../../../utils/CommonUtils';
import { dateFormatter } from '../../../utils/DateFormatter';
import logger from '../../../utils/Logging';
import { MlLogsPageContent } from '../../mlLogs/MlLogsPage';
import { TabPanel } from '../../mlPackageVersion/MLPackageVersionsPage';
import type { SkillUpdateData } from './MlSkillDetailsPackageVersions';
import { MlPackageVersions } from './MlSkillDetailsPackageVersions';
import { MLSkillRunLogs } from './MLSkillRunLogs';

const MLSkillDetailsPageContainer = styled('div')(({ theme }) => ({
    fontSize: Tokens.FontFamily.FontMSize,
    width: '100%',
    fontWeight: 600,
    fontFamily: Tokens.FontFamily.FontNormal,
    color: theme.palette.semantic.colorForeground,
    overflowY: 'auto',
    lineHeight: Tokens.FontFamily.FontMLineHeight,

    '& .mlSkillDetails': {
        height: 'auto',
        border: `solid 1px ${theme.palette.semantic.colorBorder}`,
        borderRadius: '2px',
        boxSizing: 'border-box',
        padding: '24px',
        boxShadow: 'none',
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        flexDirection: 'row',

        '& .MuiSvgIcon-root': {
            fontSize: Tokens.FontFamily.FontMSize,
            padding: 0,
        },

        '& .image': {
            display: 'flex',
            justifyContent: 'center',

            '& .MuiIconButton-root': { fontSize: '4em' },
        },

        '& .content': {
            marginLeft: '50px',
            width: '100%',

            '& .row': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                lineHeight: Tokens.FontFamily.FontHeader2LineHeight,

                '& .data': {
                    color: theme.palette.semantic.colorForeground,
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    alignItems: 'center',
                },

                '& .header': {
                    lineHeight: Tokens.FontFamily.FontMLineHeight,
                    width: '20%',
                    display: 'inline-block',
                },

                '& .value': {
                    marginLeft: '10px',
                    fontWeight: 300,
                    width: '75%',
                },

                '& .publicUrlContainer': {
                    display: 'flex',
                    flexDirection: 'row',
                    width: '75%',
                },

                '& .publicUrlText': {
                    marginLeft: '10px',
                    fontWeight: 300,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },

                '& .copyIcon': {
                    marginTop: '-2px',
                    marginLeft: '10px',
                },

                '& .fullWidthRowValue': {
                    marginLeft: '10px',
                    fontWeight: 300,
                    width: '85%',
                },

                '& .truncateText': {
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflowWrap: 'break-word',
                },

                '& .threeLineHeight': { WebkitLineClamp: 3 },

                '& .oneLineHeight': { WebkitLineClamp: 1 },

                '& .twoLineHeight': { WebkitLineClamp: 2 },

                '& .fullWidthRowHeader': {
                    lineHeight: Tokens.FontFamily.FontMLineHeight,
                    width: '10%',
                },

                '& .fullWidthRow': { width: '100%' },
            },
        },
    },
}));

const MLSkillCreateDialogContainer = styled('div')({ fontSize: Tokens.FontFamily.FontMSize });

const MLSkillCreateSkillTabContainer = styled('div')({
    width: '100%',

    '& .tabBarArea': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '15px',

        '& .tabBar': { width: '53%' },
    },
});

interface InactivePeriodThreshold {
    name: string;
    value: number;
}

interface MlSkillDetailsPageProps {
    isOnPrem: boolean;
    isPublicTenant: boolean;
    featureManager: FeatureFlagManager;
    inactivePeriodThresholds: InactivePeriodThreshold[];
    accountId: string;
}

const MlSkillDetailsPageFC: React.FC<MlSkillDetailsPageProps> = ({
    isOnPrem, isPublicTenant, featureManager, inactivePeriodThresholds, accountId,
}) => {
    const { t } = useTranslation();
    const { state } = useContext(ProjectsContext);
    const currentProject = React.useMemo(() => state.currentProject, [ state ]);
    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[currentProject?.id ?? '']?.permissionSet || [];

    const [ isAiUnitEnabled, setisAiUnitEnabled ] = React.useState<any>(false);
    const [ isMLSkillInfraSettingsEnabled, setIsMLSkillInfraSettingsEnabled ] = React.useState<any>(featureManager.isEnabled('ml-skill-infra-settings-enabled'));

    React.useEffect(() => {
        if (!isOnPrem) {
            getIsAIUnitEnabled(accountId)
                .then((res) => {
                    setisAiUnitEnabled(res?.data);
                    setIsMLSkillInfraSettingsEnabled(res?.data);
                    return res?.data;
                })
                .catch((error) => {
                    logger.error({
                        identifier: 'Core',
                        message: t('ml_aiunitenabled_fetch_error_message'),
                        error,
                    });
                    return false;
                });
        }
    }, [ isOnPrem ]);

    return currentProject?.id ? (
        <MLSkillDetailsPageContainer>
            <MlSkillDetails
                currentProject={currentProject}
                featureManager={featureManager}
                permissions={permissions}
                isOnPrem={isOnPrem}
                isPublicTenant={isPublicTenant}
                inactivePeriodThresholds={inactivePeriodThresholds}
                isAiUnitEnabled={isAiUnitEnabled}
                isMLSkillInfraSettingsEnabled={isMLSkillInfraSettingsEnabled} />
            <MlSkillDetailsTab
                currentProject={currentProject}
                featureManager={featureManager}
                permissions={permissions}
                isOnPrem={isOnPrem}
                isPublicTenant={isPublicTenant}
                isAiUnitEnabled={isAiUnitEnabled} />
        </MLSkillDetailsPageContainer>
    ) : null;
};

declare global {
    interface Window {
        currentVersion: string | undefined;
        currentStatus: string | undefined;
    }
}

interface SkillDetailsProps {
    currentProject: ProjectDto;
    permissions: AppPermissions[];
    isOnPrem: boolean;
    isPublicTenant: boolean;
    featureManager: FeatureFlagManager;
    inactivePeriodThresholds: InactivePeriodThreshold[];
    isAiUnitEnabled: boolean;
    isMLSkillInfraSettingsEnabled: boolean;
}

interface SkillDetailsTabProps {
    currentProject: ProjectDto;
    permissions: AppPermissions[];
    isOnPrem: boolean;
    isPublicTenant: boolean;
    featureManager: FeatureFlagManager;
    isAiUnitEnabled: boolean;
}

const MlSkillDetailsTab: React.FC<SkillDetailsTabProps> = ({
    currentProject, permissions, isOnPrem, isPublicTenant, featureManager, isAiUnitEnabled,
}) => {
    const feedback = useFeedback();
    const { t } = useTranslation();
    const state: any = useLocation()?.state;
    const skillData: MLSkillDto = state.data;
    const [ value, setValue ] = React.useState(0);
    const [ selectedReplica, setSelectedReplica ] = React.useState<string | undefined>('startup-1-1.log');

    // Update Tab value on status update
    const handleChange = (event: React.ChangeEvent<{}>, newValue: React.SetStateAction<number>): void => {
        setValue(newValue);
    };

    const getSkillReplicaPodNames = () => getSkillReplicas(skillData.id, skillData.projectId)
        .then((res) => {
            if (res?.replicas?.length !== 0) {
                setSelectedReplica(res?.replicas?.[0]);
            }
            return true;
        })
        .catch((error) => {
            logger.error({
                identifier: 'Core',
                message: t('ml_streaming_logs_replicas_fetch_error_message'),
                error,
            });
            return false;
        });

    // Audit logs Url for ML Skill entity wuthout project ID
    const auditsUrl = URLManager.url().apiHelper + '/audits?components=ML_SKILL,ML_SKILL_VERSION&sortBy=createdOn&sortOrder=DESC&entityId=' + skillData.mlSkillVersionId;

    const downloadMlSkillsReport = async () => {
        const replicaId = selectedReplica?.split('.')[0];
        const url = URLManager.url().apiDeployer + '/mlskills/' + skillData.id + '/mlSkillReport/' + replicaId + '?projectId=' + currentProject?.id;
        const downloaded = await downloadReport(url);
        if (downloaded.success) {
            feedback.enqueueSuccess(t('feedback_ml_skills_report_download_success'));
        } else {
            feedback.enqueueError(extractErrorMessage(
                downloaded.error, t('feedback_ml_skills_report_download_failure_reason'),
                { 10009: {} },
            ));
        }
    };

    React.useEffect(() => {
        getSkillReplicaPodNames();
    }, []);

    return (
        <Section>
            <div>
                <MLSkillCreateSkillTabContainer>
                    <div className="tabBarArea">
                        <div className="tabBar">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label={t('a11y_ml_package_version_tab_label')}
                                indicatorColor="secondary"
                                textColor="secondary">
                                <Tab label={t('ml_package_version_tab_label_version')} />
                                <Tab label={t('ml_logs_title')} />
                                <Tab label={t('ml_logs_streaming_title')} />
                            </Tabs>
                        </div>
                        {
                            isMlSkillReportDownloadEnabled(featureManager) ?
                                (
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        aria-label={t('a11y_download')}
                                        onClick={downloadMlSkillsReport}
                                        data-testid="skill-download-btn"
                                        data-cy="Download ml_skills_report_button"
                                    >
                                        {t('ml_skills_report_download')}
                                    </Button>)
                                :
                                (<></>)
                        }
                    </div>
                </MLSkillCreateSkillTabContainer>

                <TabPanel
                    value={value}
                    index={0}>
                    <MlPackageVersions
                        featureManager={featureManager}
                        isPublicTenant={isPublicTenant}
                        currentProject={currentProject}
                        permissions={permissions}
                        isOnPrem={isOnPrem}
                        isAiUnitEnabled={isAiUnitEnabled}
                    />
                </TabPanel>
                <TabPanel
                    value={value}
                    index={1}>
                    <MlLogsPageContent
                        auditsUrl={auditsUrl}
                        entityLogsPage
                        breadCrumbPath={generatePath(RoutePath.MLSKILL_DETAILS, { projectName: currentProject?.name })}
                        breadCrumbData={skillData} />
                </TabPanel>
                <TabPanel
                    value={value}
                    index={2}>
                    <MLSkillRunLogs skillData={skillData} />
                </TabPanel>
            </div>
        </Section>
    );
};

const getButtonConfig = (buttonText: string, openDialog: Function, disabled: boolean): ButtonItem => ({
    buttonText,
    /* Disable modifying when skill is updating */
    disabled,
    startIcon: 'ArrowRightAltIcon',
    variant: 'text',
    buttonCallback: () => {
        openDialog(true);
    },
});

interface MetaLimits {
    replicas: number;
    requestMemory: number;
    requestCPU: number;
    limitMemory: number;
    limitCPU: number;
}

const MlSkillDetails: React.FC<SkillDetailsProps> = ({
    currentProject, permissions, isOnPrem, isPublicTenant, featureManager, inactivePeriodThresholds, isAiUnitEnabled, isMLSkillInfraSettingsEnabled,
}) => {

    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const state: any = useLocation()?.state;
    const skillData: MLSkillDto = state.data;
    const { t } = useTranslation();
    const feedback = useFeedback();
    const { projectName } = useParams<{ projectName: string }>();

    const [ openSkillRollbackDialog, setOpenSkillRollbackDialog ] = React.useState(false);
    const [ openModifyingSkillDialog, setOpenModifyingSkillDialog ] = React.useState(false);
    const [ openRotateSkillDialog, setOpenRotateSkillDialog ] = React.useState(false);

    const ML_SKILL_UPDATE_ALLOWED_STATUSES: string[] | undefined = [ 'AVAILABLE', 'FAILED', 'STOPPED' ];

    const skillRollbackData: SkillUpdateData = generateSkillRollbackData(skillData, isOnPrem);

    const skillModifyData: SkillUpdateData = generateSkillModifyData(skillData, isOnPrem);

    const buttonItems: ButtonItem[] = [];

    if (permissions.indexOf(AppPermissions.MLSkills_Delete) > -1 && skillData.status === 'AVAILABLE') {
        buttonItems.push(getButtonConfig(t('ml_skill_detail_page_button_stop'), () =>
            stopSelectedMlSkill()
        , false));
    }

    if (permissions.indexOf(AppPermissions.MLSkills_Edit) > -1) {
        if (skillData.status === 'STOPPED') {
            buttonItems.push(
                getButtonConfig(
                    t('ml_skill_detail_page_button_resume'),
                    () => {
                        modifyMLSkill(
                            skillData.processor === 'GPU',
                            !!skillData.publicSkill,
                            !!skillData.autoUpdate,
                            skillData.inactivityPeriodInDays || -1,
                            {
                                replicas: skillData.replicas || 1,
                                requestMemory: skillData.requestedMemory || 2,
                                requestCPU: skillData.requestedCPU || 0.5,
                                limitMemory: skillData.limitMemory || 8,
                                limitCPU: skillData.limitCPU || 2,
                            },
                            'RESUME');
                    },
                    false,
                ),
            );
        }

        buttonItems.push(
            getButtonConfig(
                'ml_skill_rollback_label',
                setOpenSkillRollbackDialog,
                (!(ML_SKILL_UPDATE_ALLOWED_STATUSES.indexOf(skillData?.status ? skillData?.status : '') > -1 && skillData.previousVersion && skillData.previousVersion !== 0)),
            ),
        );
        buttonItems.push(
            getButtonConfig(
                'ml_skill_modify_current_deployemnt_label',
                setOpenModifyingSkillDialog,
                (ML_SKILL_UPDATE_ALLOWED_STATUSES.indexOf(skillData?.status ? skillData?.status : '') <= -1),
            ),
        );
    }

    /* Generate breadcrum for ML Skill detail page */
    const skillDetailsbreadcrumbProps: BreadcrumbProps =
    {
        pageTitle: skillData?.name,
        crumbs: [
            {
                title: t('ml_skills_title'),
                link: generatePath(RoutePath.MLSKILLS, { projectName }),
            },
        ],
    };

    /*  Stop Ml Skill */
    const stopSelectedMlSkill = useCallback((): void => {
        stopMLSkill(skillData?.id, currentProject?.id).then(() => {
            feedback.enqueueSuccess(t('feedback_stop_success_mlskill'));
            history.push(generatePath(RoutePath.MLSKILLS, { projectName: currentProject?.name }));
            return true;
        })
            .catch(error => {
                logger.error({
                    identifier: 'ML Skill list',
                    message: 'Error stopping ML Skill',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error, t('feedback_stop_error_mlskill'),
                    { 10009: {} },
                ));
            });
    }, []);

    /**
  * Modify exitsing Ml skill
  *
  * @param gpuRequired
  * @param isPublicSkill
  * @param isAutoUpdate
  */
    const modifyMLSkill = useCallback(async (
        gpuRequired: boolean,
        isPublicSkill: boolean,
        isAutoUpdate: boolean,
        inactivityPeriodThresholdSelected: number,
        meta: MetaLimits,
        updateType: string,
    ): Promise<void> => {
        /* call backend update skill API */
        await updateMLSkill(
            {
                deploymentsRequired: 1,
                gpuRequired: Number(gpuRequired),
                mlPackageVersionId: skillData.mlPackageVersionId || ' ',
                processor: gpuRequired ? 'GPU' : 'CPU',
                publicSkill: isPublicSkill,
                autoUpdate: isAutoUpdate,
                inactivityPeriodInDays: Number(inactivityPeriodThresholdSelected),
                replicas: meta.replicas,
                requestMemory: meta.requestMemory,
                requestCPU: meta.requestCPU,
                limitMemory: meta.limitMemory,
                limitCPU: meta.limitCPU,
            },
            skillData.id, /* ML Skill ID */
            updateType,
            currentProject?.id, /* Project ID */
        ).then(() => {
            setOpenModifyingSkillDialog(false);
            feedback.enqueueSuccess(t('feedback_update_success_mlskill'));
            history.push(generatePath(RoutePath.MLSKILLS, { projectName: currentProject?.name }));
            return true;
        })
            .catch((error: any) => {
                setOpenModifyingSkillDialog(false);
                logger.error({
                    identifier: 'ML Skill details',
                    message: 'Error updating ML Skill',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('feedback_skill_update_error'),
                    {
                        40802: {},
                        40016: {},
                    },
                ));
            });
    }, []);

    /**
   * Updates Ml skill to previeous version if exits, otherise rollback button should be disabled
   *
   * @param gpuRequired
   * @param isPublicSkill
   */
    const rollbackMLSkillToPreviousVersion = useCallback(async (gpuRequired: boolean, isPublicSkill: boolean): Promise<void> => {
        /* call backend rollback skill API */
        await rollbackMLSkill(
            {
                deploymentsRequired: 1,
                gpuRequired: Number(gpuRequired),
                publicSkill: isPublicSkill,
            },
            skillData.id, /* ML Skill ID */
            currentProject?.id, /* Project ID */
        ).then(() => {
            setOpenSkillRollbackDialog(false);
            feedback.enqueueSuccess(t('feedback_update_success_mlskill'));
            history.push(generatePath(RoutePath.MLSKILLS, { projectName: currentProject?.name }));
            return true;
        })
            .catch((error: any) => {
                setOpenSkillRollbackDialog(false);
                logger.error({
                    identifier: 'ML Skill details',
                    message: 'Error rolling back ML Skill',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('feedback_skill_update_error'),
                    {
                        40802: {},
                        40016: {},
                    },
                ));
            });
    }, []);

    /**
   * Updates Ml skill to previeous version if exits, otherise rollback button should be disabled
   *
   * @param gpuRequired
   * @param isPublicSkill
   */
    const rotateMLSkillKey = useCallback(async (): Promise<void> => {
        /* call backend update skill API */
        await updateMLSkill(
            {
                deploymentsRequired: 1,
                gpuRequired: Number(skillData.processor === 'GPU'),
                mlPackageVersionId: skillData.mlPackageVersionId || ' ',
                processor: skillData.processor || 'CPU',
                publicSkill: true,
                rotate: true,
            },
            skillData.id, /* ML Skill ID */
            'UPDATE',
            currentProject?.id, /* Project ID */
        ).then(() => {
            setOpenRotateSkillDialog(false);
            feedback.enqueueSuccess(t('feedback_update_success_mlskill'));
            history.push(generatePath(RoutePath.MLSKILLS, { projectName: currentProject?.name }));
            return true;
        })
            .catch((error: any) => {
                setOpenRotateSkillDialog(false);
                logger.error({
                    identifier: 'ML Skill details',
                    message: 'Error updating ML Skill',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('feedback_skill_update_error'),
                    {
                        40802: {},
                        40016: {},
                    },
                ));
            });
    }, []);

    window.currentVersion = skillData?.currentVersion + '.' + skillData?.currentTrainingVersion;
    window.currentStatus = skillData?.status;

    return (
        <div>
            <Section
                breadcrumProps={skillDetailsbreadcrumbProps}
                buttonItems={buttonItems}>
                <MlSkillDetailsData
                    isOnPrem={isOnPrem}
                    skillData={skillData}
                    openRotateSkillDialog={setOpenRotateSkillDialog}
                    showSkillInactivePeriod={!featureManager.isEnabled('ml-idle-skill-purge-disabled')} />

                {/* Rollback SKill dialog */}
                <MlSkillUpdateDialog
                    open={openSkillRollbackDialog}
                    title={t('ml_skill_update_dialog_title_text',
                        { mlSkillName: skillData.name })}
                    data={skillRollbackData}
                    handleClose={(): void => setOpenSkillRollbackDialog(false)}
                    closeIconButton
                    primaryButtonText={t('dialog_button_confirm_text')}
                    secondaryButtonText={t('dialog_button_cancel_text')}
                    primarybuttonCallback={rollbackMLSkillToPreviousVersion}
                    secondarybuttonCallback={(): void => setOpenSkillRollbackDialog(false)}
                    canBePublic={isPublicCapable(skillData, isOnPrem, isPublicTenant, featureManager)}
                    isAutoUpdateEnabled={featureManager.isEnabled('auto-retraining-enabled')}
                    inactivePeriodThresholds={featureManager.isEnabled('ml-idle-skill-purge-disabled') ? [] : inactivePeriodThresholds}
                />

                {/* Modify SKill dialog */}
                <MlSkillUpdateDialog
                    open={openModifyingSkillDialog}
                    title={t('ml_skill_update_dialog_title_text',
                        { mlSkillName: skillData.name })}
                    canBePublic={isPublicCapable(skillData, isOnPrem, isPublicTenant, featureManager)}
                    data={skillModifyData}
                    handleClose={(): void => setOpenModifyingSkillDialog(false)}
                    closeIconButton
                    primaryButtonText={t('dialog_button_confirm_text')}
                    secondaryButtonText={t('dialog_button_cancel_text')}
                    primarybuttonCallback={modifyMLSkill}
                    secondarybuttonCallback={(): void => setOpenModifyingSkillDialog(false)}
                    isAutoUpdateEnabled={featureManager.isEnabled('auto-retraining-enabled')}
                    isMLSkillInfraSettingsEnabled={isMLSkillInfraSettingsEnabled}
                    inactivePeriodThresholds={featureManager.isEnabled('ml-idle-skill-purge-disabled') ? [] : inactivePeriodThresholds}
                    isAiUnitEnabled={isAiUnitEnabled}
                />

                <DialogPopup
                    open={openRotateSkillDialog}
                    type={DialogType.SkillUpdate}
                    title={t('ml_skill_rotate_key_dialog_title_text')}
                    handleClose={(): void => setOpenRotateSkillDialog(false)}
                    closeIconButton
                    primaryButtonText={t('dialog_button_confirm_text')}
                    secondaryButtonText={t('dialog_button_cancel_text')}
                    primarybuttonCallback={rotateMLSkillKey}
                    secondarybuttonCallback={(): void => setOpenRotateSkillDialog(false)}
                >
                    <MLSkillCreateDialogContainer>
                        {t('ml_skill_rotate_key_dialog_title_content')}
                    </MLSkillCreateDialogContainer>
                </DialogPopup >
            </Section>
        </div>
    );
};

interface MlSkillDetailsDataProps {
    skillData: MLSkillDto;
    openRotateSkillDialog: Function;
    showSkillInactivePeriod: boolean;
    isOnPrem: boolean;
}

const MlSkillDetailsData: React.FC<MlSkillDetailsDataProps> = ({
    skillData, openRotateSkillDialog, showSkillInactivePeriod, isOnPrem,
}) => {
    const [ mlPackageTiersValue, setMlPackageTiersValue ] = useState<MlPackageTier[]>([]);

    const {
        t, i18n,
    } = useTranslation();

    /* Copy text to clipboard */
    const copyText = (): void => {
        copyToClipBoard(skillData.publicAccessUri);
    };

    const copyKey = (): void => {
        copyToClipBoard(skillData.apiKey);
    };

    const skillInputType = (
        <div className="data">
            <div className="header">
                {t('ml_skill_details_input_type_label')}
            </div>
            <div className="value">
                {dataFormatter(skillData.inputType)}
            </div>
        </div>
    );

    const getSkillVersion = (): string => {
        if (skillData.currentCustomVersion != null) {
            return skillData?.currentCustomVersion + '.' + skillData?.currentTrainingVersion;
        }
        return skillData?.currentVersion + '.' + skillData?.currentTrainingVersion;
    };

    const getResourcePerReplica = (skillData: MLSkillDto) => {
        if (skillData.processor === 'GPU') {
            return t('common_gpu_label');
        }

        const requestedMemory = skillData.requestedMemory as RequestedMemory;
        if (requestedMemory) {
            if (mlPackageTiersValue.length > 0) {
                // If MLPackageTiers are available, display the resource associated with the MLPackageTier for which the
                // AI Unit consumption is equal with the RESOURCE_PER_REPLICA_CONFIG[requestedMemory].unitValue.
                const aiUnitsConsumptionBasedOnRequestedMemory = RESOURCE_PER_REPLICA_CONFIG[requestedMemory].unitvalue;
                return mlPackageTiersValue.find(tier => tier.unitvalue === aiUnitsConsumptionBasedOnRequestedMemory)?.resource;
            }
            return RESOURCE_PER_REPLICA_CONFIG[requestedMemory].resource;
        }
        return t('common_not_available');
    };

    const getAiUnitsConsumption = (skillData: MLSkillDto) => {
        const replicas = skillData?.replicas ?? 1;
        return skillData.processor === 'GPU'
            ? GPU_AIUNITS * replicas
            : computeMlSkillResourcePerReplica(skillData.requestedMemory) * replicas;
    };

    useEffect(() => {
        const populateMlPackageTiers = async () => {
            const versions = await getMlVersionsByPackageId(skillData?.mlPackageId, skillData?.projectId);
            if (versions?.length && versions[0].tiers !== undefined) {
                const tiersValues = versions[0].tiers.map((pkgTier: TierDto) => ({
                    resource: pkgTier.resourcesPerReplica! + ' | ' + pkgTier.maxDocumentPages + ' | ' + pkgTier.throughput,
                    unitvalue: pkgTier.aiUnitsPerHour,
                }));
                setMlPackageTiersValue((prevValues) => [ ...prevValues, ...tiersValues.filter(tiersValue =>
                    !prevValues.some(prevValue => tiersValue.resource === prevValue.resource && tiersValue.unitvalue === prevValue.unitvalue)) ]);
            }
        };

        populateMlPackageTiers();
    }, []);

    return (
        <div className="mlSkillDetails">
            <HeroInfoIcon />

            <div className="content">

                <div className="row">
                    <div className="data">
                        <div className="header">
                            {t('ml_skill_details_status_label')}
                        </div>
                        <div className="value">
                            {dataFormatter(t(`ML_SKILL_STATUS_${skillData.status}`), i18n.language)}
                        </div>
                    </div>

                    <div className="data">
                        <div className="header">
                            {t('ml_skill_details_prediction_label')}
                        </div>
                        <div className="value">
                            {skillData.predictionCount}
                        </div>
                    </div>
                </div>

                <div className="row spaceFromTop">
                    <div className="data">
                        <div className="header">
                            {t('ml_skill_details_ml_package_label')}
                        </div>
                        <div className="value">
                            {skillData.mlPackageName}
                        </div>
                    </div>

                    <div className="data ">
                        <div className="header">
                            {t('ml_skill_details_version_label')}
                        </div>
                        <div className="value">
                            {getSkillVersion()}
                        </div>
                    </div>
                </div>

                <div className="row spaceFromTop">
                    <div className="data">
                        <div className="header">
                            {t('ml_skill_details_deployed_on_label')}
                        </div>
                        <div className="value">
                            {dateFormatter(skillData.createdOn, i18n.language)}
                        </div>
                    </div>
                    <div className="data">
                        <div className="header">
                            {t('ml_skill_details_modified_on_label')}
                        </div>
                        <div
                            className="value"
                            data-testid="modified-on">
                            {dateFormatter(skillData.modifiedOn, i18n.language)}
                        </div>
                    </div>
                </div>

                <div className="row spaceFromTop">
                    <div className="data">
                        <div className="header">
                            {t('ml_skill_infra_settings_replicas_label')}
                        </div>
                        <div className="value">
                            {skillData.replicas}
                        </div>
                    </div>
                    {!isOnPrem && <div className="data">
                        <div className="header">
                            {t('ml_skill_infra_ai_units_label')}
                        </div>
                        <div className="value">
                            {getResourcePerReplica(skillData)}
                        </div>
                    </div>}
                </div>

                <div className="row spaceFromTop">
                    {!isOnPrem && <div className="data">
                        <div className="header">
                            {t('infra_total_ai_units_per_hour_label')}
                        </div>
                        <div className="value">
                            {getAiUnitsConsumption(skillData)}
                        </div>
                    </div>}
                    {
                        skillData.processor !== 'GPU' ?
                            <div className="data">
                                <div className="header">
                                    {t('gpu_label')}
                                </div>
                                <div className="value">
                                    <ClearSharpIcon />
                                </div>
                            </div>
                            : null
                    }
                </div>

                <div className="row spaceFromTop">
                    <WithVisibility visible={showSkillInactivePeriod} >
                        <div className="data">
                            <div className="header">
                                {t('ml_skill_inactivity_period')}
                            </div>
                            <div
                                className="value"
                                data-testid="inactivity-period">
                                {skillData.inactivityPeriodInDays === -1 ? 'Never' : skillData.inactivityPeriodInDays + ' Days'}
                                {' '}
                            </div>
                        </div>
                    </WithVisibility>
                    <WithVisibility visible={!showSkillInactivePeriod} >
                        {skillInputType}
                    </WithVisibility>
                    {showSkillInactivePeriod ? skillInputType : null}
                </div>

                <div className="row spaceFromTop">
                    {skillData.apiKey && skillData.publicSkill ? (
                        <div className="data">
                            <div className="header">
                                {t('common_apiKey_label')}
                            </div>
                            <div className="publicUrlContainer">
                                <div
                                    className="publicUrlText"
                                    data-testid="skill-apikey">
                                    {skillData.apiKey}
                                </div>
                                <div className="copyIcon">
                                    <IconButton
                                        aria-label={t('a11y_copy_file_url')}
                                        onClick={copyKey}>
                                        <FileCopyOutlinedIcon />
                                    </IconButton>
                                </div>
                                <div className="copyIcon">
                                    <IconButton
                                        data-testid="open-rotate-skill-dialog"
                                        aria-label={t('a11y_copy_file_url')}
                                        onClick={() => {
                                            openRotateSkillDialog(true);
                                        }}>
                                        <AutoRenew />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="data" />
                    )}

                    <div className="data" />
                </div>

                <div className="row spaceFromTop">
                    {skillData.publicSkill === true ? (
                        <div className="data">
                            <div className="header">
                                {t('common_url_label')}
                            </div>
                            <div className="publicUrlContainer">
                                <div
                                    className="publicUrlText"
                                    data-testid="skill-access-uri">
                                    {skillData.publicAccessUri}
                                </div>
                                <div className="copyIcon">
                                    <IconButton
                                        aria-label={t('a11y_copy_file_url')}
                                        onClick={copyText}>
                                        <FileCopyOutlinedIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="data">
                            <div className="header">
                                {t('ml_skill_details_input_description_label')}
                            </div>
                            <div className="value truncateText threeLineHeight">
                                {dataFormatter(skillData.inputDescription)}
                            </div>
                        </div>
                    )}
                </div>

                {skillData.publicSkill === true ? (
                    <div className="row spaceFromTop">
                        <div className="data fullWidthRow">
                            <div className="fullWidthRowHeader">
                                {t('ml_skill_details_input_description_label')}
                            </div>
                            <div className="fullWidthRowValue truncateText twoLineHeight">
                                {skillData.inputDescription}
                            </div>
                        </div>
                    </div>) : null}

                <div className="row spaceFromTop">
                    <div className="data fullWidthRow">
                        <div className="fullWidthRowHeader">
                            {t('ml_skill_details_output_description_label')}
                        </div>
                        <div className="fullWidthRowValue truncateText twoLineHeight">
                            {skillData.outputDescription}
                        </div>
                    </div>
                </div>

                <div className="row spaceFromTop">
                    <div className="data fullWidthRow">
                        <div className="fullWidthRowHeader">
                            {t('ml_skill_details_description_type_label')}
                        </div>
                        <div className="fullWidthRowValue truncateText twoLineHeight">
                            {skillData.description}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const generateSkillUpdateData = (skillData: MLSkillDto): SkillUpdateData => {
    const skillUpdateData: SkillUpdateData = {} as SkillUpdateData;
    skillUpdateData.packageName = skillData?.mlPackageName;
    skillUpdateData.gpuEnabled = (skillData?.processor === 'GPU');
    skillUpdateData.isPublicSkill = skillData.publicSkill || false;
    skillUpdateData.autoUpdate = skillData.autoUpdate || false;
    skillUpdateData.replicas = skillData?.replicas;
    skillUpdateData.requestMemory = skillData?.requestedMemory;
    skillUpdateData.requestCPU = skillData?.requestedCPU;
    skillUpdateData.limitMemory = skillData?.limitMemory;
    skillUpdateData.limitCPU = skillData?.limitCPU;
    skillUpdateData.mlPackageId = skillData?.mlPackageId;
    return skillUpdateData;
};

const generateSkillRollbackData = (skillData: MLSkillDto, isOnPrem: boolean): SkillUpdateData => {
    const skillRollbackData: SkillUpdateData = generateSkillUpdateData(skillData);
    skillRollbackData.currentVersion = skillData?.currentVersion?.toString() + '.' + skillData.currentTrainingVersion?.toString();
    skillRollbackData.nextVersion = skillData?.previousVersion?.toString() + '.' + skillData?.previousTrainingVersion?.toString();
    skillRollbackData.updateType = SkillUpdateType.Rollback;
    skillRollbackData.isOnprem = isOnPrem;
    skillRollbackData.currentCustomVersion = skillData?.currentCustomVersion ? skillData?.currentCustomVersion + '.' + skillData.currentTrainingVersion?.toString() : undefined;
    skillRollbackData.nextCustomVersion = skillData?.previousCustomVersion ? skillData?.previousCustomVersion + '.' + skillData?.previousTrainingVersion?.toString() : undefined;
    return skillRollbackData;
};

const generateSkillModifyData = (skillData: MLSkillDto, isOnPrem: boolean): SkillUpdateData => {
    const skillModifyData: SkillUpdateData = generateSkillUpdateData(skillData);
    skillModifyData.currentVersion = skillData?.currentVersion?.toString() + '.' + skillData.currentTrainingVersion?.toString();
    skillModifyData.updateType = SkillUpdateType.Modifying;
    skillModifyData.isOnprem = isOnPrem;
    skillModifyData.inactivityPeriodInDays = skillData?.inactivityPeriodInDays;
    skillModifyData.replicas = skillData?.replicas;
    skillModifyData.requestMemory = skillData?.requestedMemory;
    skillModifyData.requestCPU = skillData?.requestedCPU;
    skillModifyData.limitMemory = skillData?.limitMemory;
    skillModifyData.limitCPU = skillData?.limitCPU;
    skillModifyData.currentCustomVersion = skillData?.currentCustomVersion;
    return skillModifyData;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MlSkillDetailsPage = connect((state: any) => ({
    isPublicTenant: state.config.isPublic,
    isOnPrem: state.config.paths ? state.config.paths.isOnPrem : undefined,
    featureManager: state.featureManagement.featureManager,
    inactivePeriodThresholds: state.config.inactivePeriodThresholds || [],
    accountId: state.authZ.accountId,
}))(MlSkillDetailsPageFC);
