import type { AutocompleteInputChangeReason } from '@mui/material';
import {
    MenuItem,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import type {
    MLPackageDto,
    MLPackageVersionDto,
    TierDto,
} from '@uipath/aifabric';
import type { CancelTokenSource } from 'axios';
import {
    Field,
    Formik,
    validateYupSchema,
    yupToFormErrors,
} from 'formik';
import {
    Switch,
    TextField,
} from 'formik-mui';
import { debounce } from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';
import * as Yup from 'yup';

import type FeatureFlagManager from '../../../../src/feature-flag/FeatureFlagManager';
import {
    createMLSkill,
    getIsAIUnitEnabled,
} from '../../../api/client/deployerManagerClient';
import {
    getMlPackages,
    getMlVersionsByPackageId,
} from '../../../api/client/pkgManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import AccessibleFormikInput from '../../../components/AccessibleFormikInput';
import TooltipContainer from '../../../components/AccessibleTooltip/TooltipContainer';
import { CustomDialog } from '../../../components/Dialog';
import {
    FormAutoCompleteClientSide,
    FormAutoCompleteServerSide,
} from '../../../components/FormAutoComplete';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormikErrorLabels from '../../../components/FormikErrorLabels';
import FormLayout from '../../../components/FormLayout';
import Label from '../../../components/Label';
import { WithVisibility } from '../../../components/WithVisibility';
import type { MlPackageTier } from '../../../constants/AiappConstants';
import {
    DEFAULT_RESOURCE_PER_REPLICA_CONFIG,
    GPU_AIUNITS,
    RequestedMemory,
    RESOURCE_PER_REPLICA_CONFIG,
} from '../../../constants/AiappConstants';
import { Validation as val } from '../../../constants/validation';
import { http } from '../../../http';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import { extractErrorMessage } from '../../../utils/CommonUtils';
import logger from '../../../utils/Logging';

const MLSkillCreateInfraSettingsFormContainer = styled('div')({
    width: '100%',

    '& .resources': { '& .table': { width: '40%' } },
});

const MLSkillCreateFormContainer = styled('div')({
    width: '100%',

    '& .enableGpu': { marginTop: '20px' },
});

interface InactivePeriodThreshold {
    name: string;
    value: number;
}

interface MlSkillCreateProps {
    featureManager: FeatureFlagManager;
    tenantType: string;
    inactivePeriodThresholds: InactivePeriodThreshold[];
    defaultThreshold: number;
    accountId: string;
    isOnPrem: boolean;
}

/**
 * Create Ml Skill form
 */
const MlSkillCreate: React.FC<MlSkillCreateProps> = ({
    featureManager, tenantType, inactivePeriodThresholds, defaultThreshold, accountId, isOnPrem,
}) => {

    const history = useHistory();
    const isFormReady = !!defaultThreshold;
    const { t } = useTranslation();
    const feedback = useFeedback();

    const [ inputChanged, setInputChanged ] = React.useState(false);
    const [ loadingState, setLoadingState ] = React.useState(true);

    const [ gpuWarnPopupOpen, setGpuWarnPopupOpen ] = React.useState(false);

    const [ replicaCountWarnPopupOpen, setReplicaCountWarnPopupOpen ] = React.useState(false);

    const [ selectedPackage, setSelectedPackage ] = React.useState('');
    const [ selectedPackageLanguage, setSelectedPackageLanguage ] = React.useState('');
    const [ selectedPackageMajorVersion, setSelectedPackageMajorVersion ] = React.useState('');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ packageList, setPackageList ] = React.useState<any[]>([]);

    const [ inactivePeriods, setInactivePeriods ] = React.useState<any[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ packageMajorVersionList, setPackageMajorVersionList ] = React.useState<any[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ packageMinorVersionList, setPackageMinorVersionList ] = React.useState<any[]>([]);

    const [ searchTerm, setSearchTerm ] = React.useState('');
    const [ cancelToken, setCancelToken ] = React.useState<CancelTokenSource | null>(null);
    const [ debouncedSearchTerm, setDebouncedSearchTerm ] = React.useState('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ mlPackageMajorVersionValue, setMlPackageMajorVersionValue ]: any = React.useState('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ mlPackageMinorVersionValue, setMlPackageMinorVersionValue ]: any = React.useState('');

    const [ mlPackageTiersValue, setMlPackageTiersValue ] = React.useState<MlPackageTier[]>([]);
    const [ isDuMlSkillEnabled, setIsDuMlSkillEnabled ] = React.useState(false);

    const DU_LANGUAGES = [ 'PYTHON36_DU', 'PYTHON37_DU', 'PYTHON38_DU' ];
    const duPattern: RegExp = /^23\..*/;

    let selection = '';
    const loading = (packageList.length === 0) && (loadingState);
    const majorVersionDisabled = loading || packageMajorVersionList.length === 0;
    const minorVersionDisabled = (majorVersionDisabled) || (packageMinorVersionList.length === 0);

    const { state: projectsState } = useContext(ProjectsContext);
    const currentProject = projectsState.currentProject;

    const isAutoUpdateEnabled: boolean = featureManager.isEnabled('auto-retraining-enabled');
    const isIdleSkillsDisabled: boolean = featureManager.isEnabled('ml-idle-skill-purge-disabled');
    const [ isAiUnitEnabled, setisAiUnitEnabled ] = React.useState<any>(false);
    const [ isMLSkillInfraSettingsEnabled, setIsMLSkillInfraSettingsEnabled ] = React.useState<any>(featureManager.isEnabled('ml-skill-infra-settings-enabled'));

    const debounceAction = debounce(() => {
        setDebouncedSearchTerm(searchTerm);
    }, 200);

    React.useEffect(() => {
        if (!isOnPrem) {
            getIsAIUnitEnabled(accountId)
                .then((res) => {
                    setisAiUnitEnabled(res?.data);
                    setIsMLSkillInfraSettingsEnabled(res?.data);
                    return res?.data;
                })
                .catch((error) => {
                    logger.error({
                        identifier: 'Core',
                        message: t('ml_aiunitenabled_fetch_error_message'),
                        error,
                    });
                    return false;
                });
        }
    }, [ isAiUnitEnabled ]);

    React.useEffect(() => {
        if (isOnPrem) {
            setIsDuMlSkillEnabled(DU_LANGUAGES.indexOf(selectedPackageLanguage) > -1 && duPattern.test(mlPackageMajorVersionValue?.version));
        }
    }, [ selectedPackageLanguage, mlPackageMajorVersionValue ]);

    React.useEffect(() => {
        if (inputChanged) {
            debounceAction();
            return function cleanup(): void {
                debounceAction.cancel();
            };
        }
    }, [ searchTerm ]);

    React.useEffect(() => {
        if (inputChanged || loading) {
            setPackageList([]);
            setLoadingState(true);
            loadData();
            return function cleanup(): void {
                loadData.cancel();
                if (cancelToken) {
                    cancelToken.cancel();
                }
            };
        }
    }, [ debouncedSearchTerm ]);

    const loadData = debounce(() => {
        const CancelToken = http.CancelToken;
        const source = CancelToken.source();
        setCancelToken(source);

        getMlPackages(source, currentProject?.id, selection).then((packages: MLPackageDto[] | undefined) => {
            if (packages?.length) {
                setPackageList(packages.map((pkg: MLPackageDto) => ({
                    id: pkg.id,
                    name: pkg.name,
                })));
            }
            return true;
        })
            .finally(() => {
                setLoadingState(false);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            })
            .catch((error: any) => {
                logger.error({
                    identifier: 'ML Skill create',
                    message: 'Error getting ML Packages',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('ml_package_loading_error'),
                    {
                        20003: {},
                        20004: {},
                    },
                ));
            });
    });

    React.useEffect(() => {
        if (selectedPackage && !inputChanged) {
            getMlVersionsByPackageId(selectedPackage, currentProject?.id)
                .then((versions: MLPackageVersionDto[] | undefined) => {
                    if (versions?.length) {
                        setPackageMajorVersionList(versions.map((pkg: MLPackageVersionDto) =>
                            ({
                                id: pkg.id,
                                version: pkg.customVersion != null ? pkg.customVersion : pkg.version?.toString(),
                                trainingVersion: pkg.trainingVersion?.toString(),
                                tiers: pkg.tiers,
                            })));
                        setSelectedPackageLanguage(versions[0].mlPackageLanguage ?? '');
                    }
                    return true;
                })
                .catch(error => {
                    logger.error({
                        identifier: 'ML Skill create',
                        message: 'Error getting ML Package versions',
                        error,
                    });
                    feedback.enqueueError(extractErrorMessage(
                        error,
                        t('ml_package_version_loading_error'),
                        {
                            10602: {
                                1: 'ML Package version',
                                0: selectedPackage,
                            },
                            20101: {},
                        },
                    ));
                });
        }
    }, [ selectedPackage ]);

    React.useEffect(() => {
        setMlPackageMinorVersionValue('');

        /* Filter the ML package list for selected Major version */
        if (selectedPackageMajorVersion) {
            setPackageMinorVersionList(packageMajorVersionList.filter(pkg => pkg.version === selectedPackageMajorVersion));
        }
    }, [ selectedPackageMajorVersion ]);

    React.useEffect(() => {
        setInactivePeriods(inactivePeriodThresholds.map((periodThreshold: InactivePeriodThreshold) => ({
            name: periodThreshold.name,
            value: periodThreshold.value,
        })));

        /* Filter the ML package list for selected Major version */

    }, [ inactivePeriodThresholds ]);

    const submitCreateMLSkill = async (values: any): Promise<any> => {
        try {
            if (isAiUnitEnabled === true) {
                await createMLSkill(
                    {
                        deploymentsRequired: 1,
                        description: values.skillDescription,
                        gpuRequired: Number(values.requireGpu),
                        mlPackageId: values.packageId,
                        mlPackageVersionId: values.packageMinorVersionId,
                        name: values.skillName,
                        processor: values.requireGpu === true ? 'GPU' : 'CPU',
                        projectId: currentProject?.id,
                        autoUpdate: values.autoUpdate,
                        publicSkill: values.publicMLSkill,
                        inactivityPeriodInDays: Number(values.mlSkillInactivityPeriod),
                        replicas: values.skillInfraSettings === true ? Number(values.replicas) : 1,
                        requestMemory: values.skillInfraSettings === true ? Number(values.requestAIUnits) * 2 : 2,
                        limitMemory: values.skillInfraSettings === true ? Math.max(Number(values.requestAIUnits) * 2, 9) : 9,
                        requestCPU: values.skillInfraSettings === true ? Number(values.requestAIUnits) * 0.5 : 0.5,
                        limitCPU: values.skillInfraSettings === true ? Math.max(Number(values.requestAIUnits) * 0.5, 2) : 2,
                    },
                );
            } else {
                await createMLSkill(
                    {
                        deploymentsRequired: 1,
                        description: values.skillDescription,
                        gpuRequired: Number(values.requireGpu),
                        mlPackageId: values.packageId,
                        mlPackageVersionId: values.packageMinorVersionId,
                        name: values.skillName,
                        processor: values.requireGpu === true ? 'GPU' : 'CPU',
                        projectId: currentProject?.id,
                        autoUpdate: values.autoUpdate,
                        publicSkill: values.publicMLSkill,
                        inactivityPeriodInDays: Number(values.mlSkillInactivityPeriod),
                        replicas: values.skillInfraSettings === true ? Number(values.replicas) : 1,
                        requestMemory: values.skillInfraSettings === true ? Number(values.requestMemory) : 2,
                        limitMemory: values.skillInfraSettings === true ? Number(values.limitMemory) : 8,
                        requestCPU: values.skillInfraSettings === true ? Number(values.requestCPU) : 0.5,
                        limitCPU: values.skillInfraSettings === true ? Number(values.limitCPU) : 2,
                    },
                );
            }

            feedback.enqueueSuccess(t('feedback_create_success_mlskill'));
            /* Go back to ML SKill list page */
            history.push(generatePath(RoutePath.MLSKILLS, { projectName: currentProject?.name }));
        } catch (error) {
            feedback.enqueueError(extractErrorMessage(
                error,
                t('ml_skill_create_default_error'),
                {
                    40003: { 0: values.skillName },
                    40008: { 0: values.skillName },
                    40016: {},
                    40019: {
                        0: values.skillName,
                        1: '1',
                        2: '36',
                    },
                    40021: {},
                    40802: {},
                    60016: {},
                    60021: {},
                },
            ));
        }
    };

    return isFormReady ? (
        <MLSkillCreateFormContainer>
            <Formik
                initialValues={{
                    skillName: '',
                    packageId: '',
                    packageVersion: '',
                    packageMinorVersionId: '',
                    skillDescription: '',
                    requireGpu: false,
                    autoUpdate: false,
                    publicMLSkill: false,
                    mlSkillInactivityPeriod: defaultThreshold,
                    skillInfraSettings: false,
                    replicas: isAiUnitEnabled === true && tenantType === 'ENT' ? 2 : 1,
                    requestMemory: 2,
                    requestCPU: 0.5,
                    limitMemory: 8,
                    limitCPU: 2,
                    requestAIUnits: 1,
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onSubmit={async (values): Promise<any> => {
                    if (values.replicas === 1) {
                        setReplicaCountWarnPopupOpen(true);
                        return;
                    }
                    submitCreateMLSkill(values);

                }}
                validate={(values) => {
                    const validationSchema =
                        Yup.object().shape({
                            skillName: Yup.string()
                                .required(t('ml_skill_create_name_required'))
                                .matches(val.mlSkillCreateNameValidation, t('ml_skill_name_validation_error'))
                                .max(val.mlSkillCreatePackageNameMax,
                                    t('ml_skill_create_skillName_max', { max: val.mlSkillCreatePackageNameMax })),

                            skillDescription: Yup.string().notRequired()
                                .max(val.mlSkillCreateDescriptionMax,
                                    t('ml_skill_create_description_max', { max: val.mlSkillCreateDescriptionMax })),

                            packageId: Yup.string().required(t('ml_package_required')),
                            packageVersion: Yup.string().required(t('ml_package_major_version_required_error_label')),
                            packageMinorVersionId: Yup.string().required(t('ml_package_minor_version_required_error_label')),
                            replicas: Yup.number().integer(t('ml_skill_infra_settings_value_should_be_integer_only'))
                                .positive(t('ml_skill_infra_settings_value_greater_than_one_message'))
                                .required(t('evtable_field_req'))
                                .lessThan(20),
                            requestMemory: Yup.number().integer(t('ml_skill_infra_settings_value_should_be_integer_only'))
                                .positive(t('ml_skill_infra_settings_value_greater_than_one_message'))
                                .required(t('evtable_field_req'))
                                .lessThan(values.limitMemory + 1, t('ml_skill_infra_settings_request_memory_less_than_limit_memory_message')),
                            limitMemory: Yup.number().integer(t('ml_skill_infra_settings_value_should_be_integer_only'))
                                .positive(t('ml_skill_infra_settings_value_greater_than_one_message'))
                                .required('evtable_field_req'),
                            requestCPU: Yup.number().positive(t('ml_skill_infra_settings_value_greater_than_half_message'))
                                .required(t('evtable_field_req'))
                                .lessThan(values.limitCPU + 0.1, t('ml_skill_infra_settings_request_cpu_less_than_limit_cpu_message')),
                            limitCPU: Yup.number().positive(t('ml_skill_infra_settings_value_greater_than_half_message'))
                                .required(t('evtable_field_req')),
                        });

                    try {
                        validateYupSchema(values, validationSchema, true, values);
                    } catch (err) {
                        return yupToFormErrors(err);
                    }
                    return {};
                }}
            >

                {
                    (props): React.ReactElement => {
                        const {
                            dirty,
                            setFieldValue,
                            isSubmitting,
                            handleSubmit,
                            submitForm,
                            values,
                            errors,
                        } = props;

                        /* Input changed info is true for input change and false for selection */
                        const handleSelection = (inputChangedInfo: boolean, fieldName: string, fieldValue: string): void => {
                            setFieldValue(fieldName, fieldValue);
                            setFieldValue('packageVersion', '');
                            setFieldValue('packageMinorVersionId', '');
                            setMlPackageMajorVersionValue('');
                            setMlPackageMinorVersionValue('');
                            setInputChanged(inputChangedInfo);
                            setPackageMajorVersionList([]);
                            setPackageMinorVersionList([]);
                            setMlPackageTiersValue([]);
                        };

                        /* Handle Ml package Major version selection change */
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const handleMlPackageMajorVersionSelection = (item: any, fieldName: string, fieldValue: string, reason?: AutocompleteInputChangeReason): void => {
                            // Do not update the field value again if unchanged to avoid infinite render loop
                            if (mlPackageMajorVersionValue !== fieldValue) {
                                setFieldValue(fieldName, fieldValue);
                                setMlPackageMajorVersionValue(item);
                                if (reason && (reason === 'reset' || reason === 'clear')) {
                                    setFieldValue('packageMinorVersionId', '');
                                    setMlPackageMinorVersionValue('');
                                    setPackageMinorVersionList([]);
                                }
                            }
                        };

                        /* Handle Ml package Minor version selection change */
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const handleMlPackageMinorVersionSelection = (item: any, fieldName: string, fieldValue: string): void => {
                            // Do not update the field value again if unchanged to avoid infinite render loop
                            if (mlPackageMinorVersionValue !== fieldValue) {
                                setFieldValue(fieldName, fieldValue);
                                setMlPackageMinorVersionValue(item);
                                if (mlPackageMajorVersionValue?.tiers) {
                                    mlPackageMajorVersionValue.tiers.forEach((element: TierDto) => {
                                        const newValues: MlPackageTier[] = [ {
                                            resource: !(mlPackageMajorVersionValue?.tiers?.length === 0) ? (element.resourcesPerReplica! + ' | ' + element.maxDocumentPages + ' | ' + element.throughput) : '',
                                            unitvalue: !(mlPackageMajorVersionValue?.tiers?.length === 0) ? element.aiUnitsPerHour : '',
                                        } ];
                                        setMlPackageTiersValue((prevValues) => [ ...prevValues, ...newValues.filter(newValue =>
                                            !prevValues.some(prevValue =>
                                                newValue.resource === prevValue.resource && newValue.unitvalue === prevValue.unitvalue)) ]);
                                    });
                                }
                                if (isDuMlSkillEnabled) {
                                    setFieldValue('skillInfraSettings', true);
                                    setFieldValue('requestAIUnits', RESOURCE_PER_REPLICA_CONFIG[RequestedMemory.Four].unitvalue);
                                } else {
                                    setFieldValue('skillInfraSettings', false);
                                    setFieldValue('requestAIUnits', DEFAULT_RESOURCE_PER_REPLICA_CONFIG.unitvalue);
                                }
                            }
                        };

                        selection = inputChanged ? values.packageId : '';

                        if (inputChanged) {
                            setSelectedPackage('');
                            setSearchTerm(values.packageId || '*UNDEFINED_OR_NULL_VALUE*');
                        } else {
                            setSelectedPackage(values.packageId);
                            setSearchTerm('');
                            setDebouncedSearchTerm('');
                        }

                        setSelectedPackageMajorVersion(values.packageVersion);

                        const cancelDialog = (): void => {
                            setGpuWarnPopupOpen(false);
                            setFieldValue('requireGpu', false);
                        };

                        const confirmDialog = (): void => {
                            setGpuWarnPopupOpen(false);
                        };

                        const confirmReplicaCountWarningDialog = (): void => {
                            setReplicaCountWarnPopupOpen(false);
                            submitCreateMLSkill(values);
                        };

                        const cancelReplicaCountWarningDialog = (): void => {
                            setReplicaCountWarnPopupOpen(false);
                        };

                        return (
                            <>
                                <CustomDialog
                                    title={t('ml_skill_replica_count_warning_popup_title')}
                                    open={replicaCountWarnPopupOpen}
                                    handleClose={cancelReplicaCountWarningDialog}
                                    closeIconButton
                                    infoText={t('tooltip_mlskills_replica_count')}
                                    primaryButtonText={t('dialog_button_confirm_text')}
                                    secondaryButtonText={t('dialog_button_cancel_text')}
                                    primarybuttonCallback={confirmReplicaCountWarningDialog}
                                    secondarybuttonCallback={cancelReplicaCountWarningDialog}
                                />
                                <CustomDialog
                                    title={t('ml_skill_enable_gpu_warning_title')}
                                    open={gpuWarnPopupOpen}
                                    handleClose={cancelDialog}
                                    closeIconButton
                                    infoText={t('ml_skill_enable_gpu_warning_text')}
                                    primaryButtonText={t('dialog_button_confirm_text')}
                                    secondaryButtonText={t('dialog_button_cancel_text')}
                                    primarybuttonCallback={confirmDialog}
                                    secondarybuttonCallback={cancelDialog}
                                />
                                <FormLayout
                                    onSubmit={handleSubmit}
                                    submitForm={submitForm}
                                    footer={
                                        <FormButtonGroup
                                            dirty={dirty}
                                            isSubmitting={isSubmitting}
                                        />
                                    }
                                >
                                    <FormikErrorLabels errors={errors} />
                                    <h2
                                        className='formTitle'
                                        id='page-title'
                                        tabIndex={0}
                                    >
                                        {t('ml_skill_create_title')}
                                    </h2>
                                    <Typography>
                                        {t('tooltip_mlskills_desc')}
                                    </Typography>
                                    <Label
                                        value={t('ml_skill_create_name_label')}
                                        required
                                        aria-label={`${t('ml_skill_create_name_label')} label`} />
                                    <Field
                                        name="skillName"
                                        type="text"
                                        component={AccessibleFormikInput}
                                        requiredField
                                        isValid={!errors['skillName']}
                                        className="textFeild min-width-override"
                                        variant="outlined"
                                        color="secondary"
                                        data-testid="skillName"
                                        placeholder={t('ml_skill_create_name_label_ph')}
                                        aria-label={`${t('ml_Skill_create_choose_package_label')} text field`}
                                        autoComplete="off"
                                        disabled={isSubmitting}
                                    />

                                    <Label
                                        value={t('ml_Skill_create_choose_package_label')}
                                        required
                                        aria-label={`${t('ml_Skill_create_choose_package_label')} label`} />
                                    <Field
                                        name="packageId"
                                        options={packageList}
                                        loading={loading}
                                        component={FormAutoCompleteServerSide}
                                        isInvalid={!!errors['packageId']}
                                        data-testid="packageName"
                                        requiredField
                                        className="min-width-override"
                                        type="select"
                                        labelKey="name"
                                        valueField="id"
                                        loadingText={t('auto_complete_loading_text')}
                                        noOptionsText={t('auto_complete_no_content_found')}
                                        handleSelection={handleSelection}
                                        placeholder={t('auto_complete_select_ml_package_place_holder')}
                                        aria-label={`${t('auto_complete_select_ml_package_place_holder')} text field`}
                                        disabled={isSubmitting}
                                    />

                                    <Label
                                        value={t('ml_package_choose_package_major_version_label')}
                                        required
                                        aria-label={`${t('ml_package_choose_package_major_version_label')} label`} />
                                    <Field
                                        className="min-width-override"
                                        name="packageVersion"
                                        options={packageMajorVersionList.filter((pkg, i, a) => a.findIndex(tmp => (tmp.version === pkg.version)) === i)}
                                        component={FormAutoCompleteClientSide}
                                        data-testid="packageMajorVersion"
                                        isInvalid={!!errors['packageVersion']}
                                        requiredField
                                        type="select"
                                        labelKey="version"
                                        valueField="version"
                                        value={mlPackageMajorVersionValue}
                                        handleSelection={handleMlPackageMajorVersionSelection}
                                        loadingText={t('auto_complete_loading_text')}
                                        noOptionsText={t('auto_complete_no_content_found')}
                                        placeholder={t('ml_package_choose_package_major_version_label')}
                                        aria-label={`${t('ml_package_choose_package_major_version_label')} text field`}
                                        disabled={majorVersionDisabled || isSubmitting}
                                    />

                                    <Label
                                        value={t('ml_package_choose_package_minor_version_label')}
                                        required
                                        aria-label={`${t('ml_package_choose_package_minor_version_label')} label`} />
                                    <Field
                                        className="min-width-override"
                                        name="packageMinorVersionId"
                                        options={packageMinorVersionList}
                                        component={FormAutoCompleteClientSide}
                                        data-testid="packageMinorVersion"
                                        isInvalid={!!errors['packageMinorVersionId']}
                                        requiredField
                                        type="select"
                                        labelKey="trainingVersion"
                                        valueField="id"
                                        value={mlPackageMinorVersionValue}
                                        loadingText={t('auto_complete_loading_text')}
                                        noOptionsText={t('auto_complete_no_content_found')}
                                        handleSelection={handleMlPackageMinorVersionSelection}
                                        placeholder={t('ml_package_choose_package_minor_version_label')}
                                        aria-label={`${t('ml_package_choose_package_minor_version_label')} text field`}
                                        disabled={minorVersionDisabled || isSubmitting}
                                    />

                                    <Label value={t('ml_skill_create_description')} />
                                    <Field
                                        className="min-width-override"
                                        name="skillDescription"
                                        type="text"
                                        component={AccessibleFormikInput}
                                        variant="outlined"
                                        color="secondary"
                                        placeholder={t('ml_skill_create_description_placeholder')}
                                        aria-label={`${t('ml_skill_create_description_placeholder')} text field`}
                                        disabled={isSubmitting}
                                        autoComplete="off"
                                        multiline
                                        rows={6}
                                    />

                                    {isAiUnitEnabled !== true ?
                                        <div className="enableGpu">
                                            <Label value={t('ml_skill_create_enableGpu_label')} />
                                            <div aria-label={`${t('ml_skill_create_enableGpu_label')} switch ${values.requireGpu ? 'checked' : 'unchecked'}`}>
                                                <Field
                                                    name="requireGpu"
                                                    component={Switch}
                                                    inputProps={{ 'aria-label': t('ml_skill_create_enableGpu_label') }}
                                                    color="secondary"
                                                    type="checkbox"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                        </div> : null}

                                    {isAutoUpdateEnabled === true ?
                                        <div className="autoUpdate">
                                            <TooltipContainer
                                                title={t('tooltip_mlskills_auto_update')}
                                                customStyle={{ marginTop: '20px' }}>
                                                <Label value={t('ml_skill_create_enable_autoUpdate_label')} />
                                            </TooltipContainer>
                                            <div aria-label={`${t('ml_skill_create_enable_autoUpdate_label')} switch ${values.autoUpdate ? 'checked' : 'unchecked'}`}>
                                                <Field
                                                    name="autoUpdate"
                                                    component={Switch}
                                                    inputProps={{ 'aria-label': t('ml_skill_create_enable_autoUpdate_label') }}
                                                    color="secondary"
                                                    type="checkbox"
                                                    disabled={isSubmitting}
                                                    data-testid="skillAutoUpdateCreatePage"
                                                />
                                            </div>
                                        </div> : null}

                                    <div className="autoUpdate">
                                        <TooltipContainer
                                            title={t('tooltip_mlskills_public')}
                                            customStyle={{ marginTop: '20px' }}>
                                            <Label value={t('common_make_ml_skill_public')} />
                                        </TooltipContainer>
                                        <div>
                                            <Field
                                                name="publicMLSkill"
                                                component={Switch}
                                                inputProps={{ 'aria-label': t('common_make_ml_skill_public') }}
                                                color="secondary"
                                                type="checkbox"
                                                disabled={isSubmitting}
                                                data-testid="public-skill-toggle"
                                            />
                                        </div>
                                    </div>

                                    <WithVisibility visible={!isIdleSkillsDisabled}>
                                        <TooltipContainer
                                            title={t('tooltip_mlskills_undeploy_inactivity')}
                                            customStyle={{ marginTop: '20px' }}>
                                            <Label
                                                value={t('ml_Skill_choose_mlSkillInactivityPeriod')}
                                                aria-label={`${t('ml_Skill_choose_mlSkillInactivityPeriod')} label`} />
                                        </TooltipContainer>
                                        <Field
                                            component={AccessibleFormikInput}
                                            className="textFeild min-width-override"
                                            select
                                            type="text"
                                            name="mlSkillInactivityPeriod"
                                            variant="outlined"
                                            color="secondary"
                                            disabled={isSubmitting}
                                            aria-label={`${t('auto_complete_select_inactivity_period_place_holder')} text field`}
                                        >
                                            <MenuItem
                                                value=""
                                                disabled>
                                                {' '}
                                                {t('auto_complete_select_inactivity_period_place_holder')}
                                                {' '}
                                            </MenuItem>
                                            {inactivePeriods.map((period, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={period.value}
                                                    selected={defaultThreshold === period.value}>
                                                    {period.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </WithVisibility>

                                    {isMLSkillInfraSettingsEnabled === true ?
                                        <div>
                                            <TooltipContainer
                                                title={t('tooltip_mlskills_infra_settings')}
                                                customStyle={{ marginTop: '20px' }}>
                                                <Label value={t('dialog_enable_mlskill_infra_settings')} />
                                            </TooltipContainer>
                                            <div aria-label={`"infraSettings" switch ${values.skillInfraSettings ? 'checked' : 'unchecked'}`}>
                                                <Field
                                                    name="skillInfraSettings"
                                                    component={Switch}
                                                    color="secondary"
                                                    type="checkbox"
                                                    disabled={isSubmitting}
                                                    aria-label={t('ally_enable_ml_skill_infra_settings')}
                                                />
                                            </div>

                                            {values.skillInfraSettings ?
                                                <div>
                                                    {
                                                        isAiUnitEnabled === true ?
                                                            <MLSkillCreateInfraSettingsFormContainer>
                                                                <div className="enableGpu">
                                                                    <TooltipContainer
                                                                        title={t('tooltip_mlskills_enable_gpu')}
                                                                        customStyle={{ marginTop: '20px' }}>
                                                                        <Label value={t('ml_skill_create_enableGpu_label')} />
                                                                    </TooltipContainer>
                                                                    <div aria-label={`${t('ml_skill_create_enableGpu_label')} switch ${values.requireGpu ? 'checked' : 'unchecked'}`}>
                                                                        <Field
                                                                            name="requireGpu"
                                                                            component={Switch}
                                                                            inputProps={{ 'aria-label': t('ml_skill_create_enableGpu_label') }}
                                                                            color="secondary"
                                                                            type="checkbox"
                                                                            disabled={isSubmitting}
                                                                            onClick={() => {
                                                                                if (!values.requireGpu) {
                                                                                    setGpuWarnPopupOpen(true);
                                                                                    setFieldValue('requestAIUnits', DEFAULT_RESOURCE_PER_REPLICA_CONFIG.unitvalue);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="aiunits">
                                                                    <TooltipContainer
                                                                        title={t('tooltip_mlskills_replica_count')}
                                                                        customStyle={{ marginTop: '20px' }}>
                                                                        <Label value={t('ml_skill_infra_settings_replicas_label')} />
                                                                    </TooltipContainer>
                                                                    <Field
                                                                        style={{ width: '8em' }}
                                                                        component={TextField}
                                                                        variant="outlined"
                                                                        name="replicas"
                                                                        type="number"
                                                                        inputProps={{
                                                                            step: '1',
                                                                            min: '1',
                                                                        }}
                                                                        color="secondary"
                                                                        aria-hidden
                                                                        aria-label={t('ally_enter_replicas')}
                                                                    />
                                                                    <Label
                                                                        value={t('ml_skill_infra_ai_units_label')}
                                                                        aria-label={`${t('ml_skill_infra_ai_units_label')} label`} />
                                                                    <Field
                                                                        component={AccessibleFormikInput}
                                                                        className="textFeild min-width-override"
                                                                        select
                                                                        type="text"
                                                                        name="requestAIUnits"
                                                                        variant="outlined"
                                                                        color="secondary"
                                                                        disabled={values.requireGpu || isSubmitting}
                                                                        aria-label={`${t('auto_complete_select_ml_skill_infra_ai_units_place_holder')} text field`}
                                                                    >
                                                                        <MenuItem
                                                                            value=""
                                                                            disabled>
                                                                            {' '}
                                                                            {t('auto_complete_select_ml_skill_infra_ai_units_place_holder')}
                                                                            {' '}
                                                                        </MenuItem>

                                                                        {(mlPackageTiersValue.length === 0 ? Object.values(RESOURCE_PER_REPLICA_CONFIG) : mlPackageTiersValue).map((mapping, index) => (
                                                                            <MenuItem
                                                                                key={index}
                                                                                value={mapping['unitvalue']}>
                                                                                {mapping['resource']}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Field>
                                                                </div>
                                                            </MLSkillCreateInfraSettingsFormContainer>
                                                            :
                                                            <MLSkillCreateInfraSettingsFormContainer>
                                                                <div className="replicas">
                                                                    <Label value={t('ml_skill_infra_settings_replicas_label')} />
                                                                    <Field
                                                                        style={{ width: '8em' }}
                                                                        component={TextField}
                                                                        variant="outlined"
                                                                        name="replicas"
                                                                        type="number"
                                                                        inputProps={{
                                                                            step: '1',
                                                                            min: '1',
                                                                        }}
                                                                        color="secondary"
                                                                        aria-hidden
                                                                        aria-label={t('ally_enter_replicas')}
                                                                    />
                                                                    <Label value={t('ml_skill_infra_settings_resources_label')} />
                                                                </div>
                                                                <div className="resources">
                                                                    <Table className="table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    {t('ml_skill_infra_settings_request_label')}
                                                                                </TableCell>
                                                                                <TableCell />
                                                                                <TableCell>
                                                                                    {t('ml_skill_infra_settings_limit_label')}
                                                                                </TableCell>
                                                                                <TableCell />
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    {t('ml_skill_infra_settings_ram_gb_label')}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Field
                                                                                        style={{ width: '8em' }}
                                                                                        component={TextField}
                                                                                        variant="outlined"
                                                                                        name="requestMemory"
                                                                                        type="number"
                                                                                        inputProps={{
                                                                                            step: '1',
                                                                                            min: '1',
                                                                                        }}
                                                                                        color="secondary"
                                                                                        aria-hidden
                                                                                        aria-label={t('ally_enter_request_memory')}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {t('ml_skill_infra_settings_ram_gb_label')}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Field
                                                                                        style={{ width: '8em' }}
                                                                                        component={TextField}
                                                                                        variant="outlined"
                                                                                        name="limitMemory"
                                                                                        type="number"
                                                                                        inputProps={{
                                                                                            step: '1',
                                                                                            min: '1',
                                                                                        }}
                                                                                        color="secondary"
                                                                                        aria-hidden
                                                                                        aria-label={t('ally_enter_limit_memory')}
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    {t('ml_skill_infra_settings_cpu_label')}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Field
                                                                                        style={{ width: '8em' }}
                                                                                        component={TextField}
                                                                                        variant="outlined"
                                                                                        name="requestCPU"
                                                                                        type="number"
                                                                                        inputProps={{
                                                                                            step: '0.1',
                                                                                            min: '0.5',
                                                                                        }}
                                                                                        color="secondary"
                                                                                        aria-hidden
                                                                                        aria-label={t('ally_enter_request_cpu')}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {t('ml_skill_infra_settings_cpu_label')}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Field
                                                                                        style={{ width: '8em' }}
                                                                                        component={TextField}
                                                                                        variant="outlined"
                                                                                        name="limitCPU"
                                                                                        type="number"
                                                                                        inputProps={{
                                                                                            step: '0.1',
                                                                                            min: '0.5',
                                                                                        }}
                                                                                        color="secondary"
                                                                                        aria-hidden
                                                                                        aria-label={t('ally_enter_limit_cpu')}
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </div>
                                                            </MLSkillCreateInfraSettingsFormContainer>
                                                    }
                                                </div>
                                                : null}
                                        </div> : null}

                                    {isAiUnitEnabled === true ?
                                        <div>
                                            <Label value={`${t('infra_total_ai_units_per_hour_label')} ${values.requireGpu ? GPU_AIUNITS * values.replicas : values.requestAIUnits * values.replicas}`} />
                                        </div>
                                        : null}
                                </FormLayout>
                            </>
                        );
                    }
                }
            </Formik>
        </MLSkillCreateFormContainer >
    ) : null;
};

export default connect((state: any) => ({
    featureManager: state.featureManagement.featureManager,
    tenantType: state.config.tenantType,
    inactivePeriodThresholds: state.config.inactivePeriodThresholds || [],
    defaultThreshold: state.config.defaultThreshold,
    accountId: state.authZ.accountId,
    isOnPrem: state.config.paths ? state.config.paths.isOnPrem : undefined,
}))(MlSkillCreate);
